<template>
  <div class="about">
    <div class="about__header">
      <h1 class="about__header_title">About The Grottas</h1>
    </div>

    <div class="about__body">
      <img
        class="about__body_photo"
        v-webp="require('@/assets/images/about/sandy-and-lou-people.jpg')"
        src="@/assets/images/about/sandy-and-lou-people.jpg"
        alt="Sandy and Lou"
      />
      <span class="about__body_photo-description">Sandy and Lou</span>

      <span class="about__body_description">
        Lou and Sandy Grotta met at the University of Michigan in 1953. After attending art history
        courses together they discovered a mutual admiration for contemporary architecture. A love
        of craft followed, spurred by their encounters with the works of Joyce and Edgar Anderson,
        Toshiko Takaezu, Chief Don Lelooska and Charles Laloma among many others.
      </span>

      <img
        class="about__body_photo"
        v-webp="require('@/assets/images/about/sandy-and-lou.png')"
        src="@/assets/images/about/sandy-and-lou.png"
        alt="Sandy and Lou"
        decoding="async"
        loading="lazy"
      />
      <span class="about__body_photo-description">Sandy and Lou</span>

      <span class="about__body_description">
        In the early 80s, Lou reunited with childhood friend, architect, Richard Meier. The two
        agreed to collaborate on a home for Sandy and Lou and their art Over a span of five years,
        the Grottas and Meier worked together to design and build The Grotta House – a space that
        would integrate the Grottas’ collection with Meier’s design to produce an enduring harmony
        between an ever-changing set of objects and the space they occupy.
      </span>

      <img
        class="about__body_photo"
        v-webp="require('@/assets/images/about/sandy_grotta.jpg')"
        src="@/assets/images/about/sandy_grotta.jpg"
        alt="Sandy Grotta"
        decoding="async"
        loading="lazy"
      />
      <span class="about__body_photo-description">Sandy Grotta</span>

      <span class="about__body_description">
        During the construction of their Richard Meier-designed home, Sandy served as chief curator,
        adding new additions to their collection of international craft art. She continued her
        renowned interior design practice and served various roles at the American Craft Museum, in
        New York City. She became a board member when the ACM became the Museum of Arts and Design
        in 2002.
      </span>

      <img
        class="about__body_photo"
        v-webp="require('@/assets/images/about/lou_grotta.jpg')"
        src="@/assets/images/about/lou_grotta.jpg"
        alt="Lou Grotta"
        decoding="async"
        loading="lazy"
      />
      <span class="about__body_photo-description">Lou Grotta</span>

      <!-- <span class="about__body_description italic"
        >“I have become increasingly interested in how the internet can permit artwork and fine
        craft to be seen continually and by larger audiences,”
      </span>
      <span class="about__body_description quote">Lou Grotta explains.</span>

      <span class="about__body_description italic"
        >“Museums have large holdings, but they have limited exhibition space. When you look at
        potential exhibition space for fine craft there’s even less real estate. Technology provides
        a new way to bring this work to audiences and academics who may never see it in museums.
        That’s why we’ve created The Grotta House website: to share exceptional art and craft and
        jewelry and to highlight the stories of the artists who created it.”
      </span> -->

      <!-- <span class="about__body_description italic"
        >“I have become increasingly interested in how the internet can permit artwork and fine
        craft to be seen continually and by larger audiences”,
        <span style="font-style: initial">Lou Grotta explains.</span>
        “Museums have large holdings, but they have limited exhibition space. When you look at
        potential exhibition space for fine craft there’s even less real estate. Technology provides
        a new way to bring this work to audiences and academics who may never see it in museums.
        That’s why we’ve created The Grotta House website: to share exceptional art and craft and
        jewelry and to highlight the stories of the artists who created it.”
      </span> -->
    </div>

    <div class="navigation__container">
      <span class="navigation__container_title">View: The House</span>
      <div class="navigation__container_buttons-box">
        <BaseButton
          class="button--white button--oval"
          text="Inside"
          @click.native="goToPage('/interiors')"
        />

        <BaseButton
          class="button--white button--oval"
          text="Outside"
          @click.native="goToPage('/exteriors')"
        />

        <BaseButton
          class="button--outline-white button--oval"
          text="Back to Home"
          @click.native="goToPage('/')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapMutations } from "vuex";

export default {
  components: { BaseButton },

  data() {
    return {};
  },

  head: {
    title: function () {
      return {
        inner: "GH - About The Grottas",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {},

  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "About",
        to: "/about",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),

    goToPage(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 3.5rem;
  @media only screen and (max-width: $xxs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &__header {
    margin-top: 15.6rem;
    @media only screen and (max-width: $xxs) {
      margin-top: 18rem;
      padding: 0rem 2.8rem;
    }

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
      @media only screen and (max-width: $xxs) {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 28.5rem 13rem;
    @media only screen and (max-width: $lg) {
      padding: 2.5rem 18.5rem 13rem;
    }
    @media only screen and (max-width: $sm) {
      padding: 2.5rem 10.5rem 13rem;
    }
    @media only screen and (max-width: $xxs) {
      padding: 4.7rem 2.8rem 15rem;
    }

    &_description {
      display: inline-block;
      text-align: justify;
      @media only screen and (max-width: $xs) {
        text-align: left;
      }

      &.quote {
        margin-bottom: 6.6rem;
      }

      &.italic {
        font-style: italic;
      }
    }

    &_photo {
      margin-top: 8.1rem;

      &-description {
        text-align: center;
        font-family: $fontBodoni;
        margin-bottom: 8.1rem;
      }
    }

    &_quote {
      font-family: $fontBodoni;
      font-size: 2.7rem;
      line-height: 3.6rem;
      @media only screen and (max-width: $xxs) {
        font-size: 2.3rem;
      }
    }
  }

  .navigation__container {
    display: flex;
    flex-direction: column;
    margin: 3.5rem 0rem 10.4rem;
    padding-left: 30%;
    width: 100%;
    @media only screen and (max-width: $xxs) {
      margin: 0rem 0rem 18.9rem;
      padding-left: 2.6rem;
    }

    &_title {
      font-family: $fontBodoni;
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin: 0rem 0rem 5rem 1.2rem;
      @media only screen and (max-width: $xxs) {
        font-size: 2.5rem;
        line-height: 3.1rem;
        margin: 0rem 0rem 3rem 0rem;
      }
    }

    &_buttons-box {
      display: flex;
      align-items: center;
      @media only screen and (max-width: $sm) {
        flex-wrap: wrap;
      }

      &::after {
        content: "";
        background-color: $white;
        height: 1px;
        flex-grow: 100;
        @media only screen and (max-width: $sm) {
          margin-top: 16px;
        }
      }

      .button--oval {
        margin-left: 12px;
        font-family: $fontBodoni;
        min-width: 185px;
        @media only screen and (max-width: $sm) {
          margin-top: 1.7rem;
        }
      }

      .button--outline {
        font-weight: 700;
        min-width: 220px;
      }
    }
  }
}
</style>

